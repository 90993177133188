<template>
  <v-flex pb-5 mb-4>
    <v-form v-on:submit.prevent="save">
      <v-container grid-list-xl>
        <v-layout wrap>
          <v-flex
            xs12
            sm6
          >
            <v-text-field
              type="email"
              filled
              v-model="account.email"
              label="E-Mail"
              v-validate="'required|email'"
              data-vv-name="email"
              data-vv-as="E-Mail"
              :error-messages="errors.collect('email')"
              required
              :loading="loading"
            ></v-text-field>
          </v-flex>

          <v-flex
            xs12
            sm6
          >
            <v-text-field
              filled
              ref="password"
              v-model="account.password"
              type="password"
              name="input-password"
              label="Contraseña Actual"
              hint="Cualquier modificación requiere que ingrese su contraseña actual"
              persistent-hint
              counter
              required
              v-validate="'required'"
              data-vv-name="password"
              data-vv-as="Contraseña Actual"
              :error-messages="errors.collect('password')"
              :loading="loading"
            ></v-text-field>
          </v-flex>

          <v-flex
            xs12
            sm6
          >
            <v-text-field
              filled
              ref="new_password"
              v-model="account.new_password"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              name="input-new-password"
              label="Nueva Contraseña"
              hint="Por lo menos 6 caracteres"
              counter
              @click:append="showPassword = !showPassword"
              v-validate="''"
              data-vv-name="new_password"
              data-vv-as="Nueva Contraseña"
              :error-messages="errors.collect('new_password')"
              :loading="loading"
            ></v-text-field>
          </v-flex>

          <v-flex
            xs12
            sm6
          >
            <v-text-field
              filled
              ref="confirmed_new_password"
              v-model="confirmed_new_password"
              type="password"
              name="input-confirmed-new-password"
              label="Confirmar Nueva Contraseña"
              counter
              v-validate="'confirmed:new_password'"
              data-vv-name="confirmed_new_password"
              data-vv-as="Confirmar Nueva Contraseña"
              :error-messages="errors.collect('confirmed_new_password')"
              :loading="loading"
            ></v-text-field>
          </v-flex>

          <v-flex
            xs12
          >
            <v-btn
              type="submit"
              color="primary"
              :loading="saving"
            >
              Guardar
            </v-btn>

            <v-btn
              to="/"
              class="ml-2"
              :disabled="saving"
            >
              Cancelar
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-flex>
</template>

<script>

export default {
  data: () => ({
    loading: true,
    saving: false,
    showPassword: false,
    account: {
      email: '',
      new_password: '',
      password: '',
    },
    confirmed_new_password: ''
  }),

  methods: {
    fetchData: function () {
      this.loading = true

      this.$http.get('cuenta/usuario')
      .then((response) => {
        this.account = response.data
        this.account.password = ''
      })
      .catch(() => {
        this.$eventHub.$emit('snackbar-message', 'Disculpe, no se pudieron cargar los datos.', 'error')
      })
      .then(() => {
        this.loading = false
      })
    },
    save: function () {
      this.$validator.validateAll()
        .then(result => {
          if (!result) return;

          this.update()
        });
    },
    update: function () {
      this.saving = true

      this.$http.put('cuenta/usuario', this.account)
      .then(() => {
        this.$refs.new_password.reset()
        this.$refs.password.reset()
        this.$refs.confirmed_new_password.reset()
        this.$validator.reset()
        this.$eventHub.$emit('snackbar-message', 'El perfil fue actualizado!', 'success')
      })
      .catch((error) => {
        switch (error.response.status) {
          case 400:
            this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

            Object.keys(error.response.data).forEach(key => {
              let value = error.response.data[key]

              this.errors.add({
                scope: 'account',
                field: key,
                msg: value
              });
            });
            break;
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
        }
      })
      .then(() => {
        this.saving = false
      })
    }
  },

  created: function () {
    this.fetchData()
  }
}
</script>
